import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ pageType, linkTitle, pageURL, linkImage, pageTitle, pageDescription }) {

  const data = useStaticQuery(graphql`
  query {
    iphone5: file(relativePath: {eq: "splashscreens/iphone5_splash.png"}) {
      publicURL
    }
    iphone6: file(relativePath: {eq: "splashscreens/iphone6_splash.png"}) {
      publicURL
    }
    iphonePlus: file(relativePath: {eq: "splashscreens/iphoneplus_splash.png"}) {
      publicURL
    }
    iphoneX: file(relativePath: {eq: "splashscreens/iphonex_splash.png"}) {
      publicURL
    }
    iphoneXr: file(relativePath: {eq: "splashscreens/iphonexr_splash.png"}) {
      publicURL
    }
    iphoneXsMax: file(relativePath: {eq: "splashscreens/iphonexsmax_splash.png"}) {
      publicURL
    }
    ipad: file(relativePath: {eq: "splashscreens/ipad_splash.png"}) {
      publicURL
    }
    ipadPro1: file(relativePath: {eq: "splashscreens/ipadpro1_splash.png"}) {
      publicURL
    }
    ipadPro3: file(relativePath: {eq: "splashscreens/ipadpro3_splash.png"}) {
      publicURL
    }
    ipadPro2: file(relativePath: {eq: "splashscreens/ipadpro2_splash.png"}) {
      publicURL
    }
  }
  `)

  return (
    <Helmet>
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={pageType} />
      <meta property="og:title" content={linkTitle} />
      <meta name="description" content={pageDescription} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:site_name" content="Gary Hynds" />
      <meta property="og:image" content={linkImage} />
      <meta property="og:image:secure_url" content={linkImage} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={linkTitle} />
      <meta name="twitter:site" content="@GaryHynds" />
      <meta name="twitter:image" content={linkImage} />
      <meta name="twitter:creator" content="Gary Hynds" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <link href={data.iphone5.publicURL} media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.iphone6.publicURL} media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.iphonePlus.publicURL} media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
      <link href={data.iphoneX.publicURL} media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
      <link href={data.iphoneXr.publicURL} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.iphoneXsMax.publicURL} media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)" rel="apple-touch-startup-image" />
      <link href={data.ipad.publicURL} media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.ipadPro1.publicURL} media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.ipadPro3.publicURL} media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <link href={data.ipadPro2.publicURL} media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)" rel="apple-touch-startup-image" />
      <title>{pageTitle}</title>
    </Helmet>
  )
}

export default SEO
